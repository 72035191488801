// .ant-steps-item-wait {
//   .ant-steps-item-title::after {
//     background-color: #BDBDBD !important;
//     margin-top: -3px !important;
//   }
// }

// .ant-steps-item-finish {
//   .ant-steps-item-title::after{
//     background-color: #00BD52 !important;
//     margin-top: -3px !important;
//   }
// }

.step-2 {
  .ant-steps-item-active {
    .ant-steps-item-title::after {
      background-color: #EAECF0 !important;
      margin-top: -3px !important;
    }
  }
  .ant-steps-item-wait {
    .ant-steps-item-title::after {
      background-color: #EAECF0 !important;
      margin-top: -3px !important;
    }
  }
  .ant-steps-item-finish {
    .ant-steps-item-title::after{
      background-color: #00BD52 !important;
      margin-top: -3px !important;
    }
  }
}