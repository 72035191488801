.status {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.text-sub-panel {
  color: #1D2939;
}

.ant-collapse-content-box {
  background: "#FFFF" !important;
}