.btn-primary {
  padding: 22px 25px;
  background: #00BD52;
  border: none;
  color: #FFFFFF;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
}

.btn-primary:hover {
  background: #00BD52 !important;
  border: none !important;
  color: #FFFFFF !important;
}

.btn-default {
  padding: 22px 25px;
  background: #FFFFFF;
  border: 1px solid #D0D5DD;
  color: #344054;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
}

.btn-default:hover {
  background: #FFFFFF !important;
  border: 1px solid #D0D5DD !important;
  color: #344054 !important;
}

.btn-tag {
  padding: 10px 5px;
  background: #EEFFF4;
  border: none;
  color: #01A24A;
  display: flex;
  justify-content: center;
  align-items: center;
}

.btn-tag:hover {
  background: #EEFFF4 !important;
  border: none !important;
  color: #01A24A !important;
}

.btn-navigation {
  padding: 10px 10px;
  background: #EEFFF4;
  border: none;
  color: #01A24A;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 600;
}

.btn-navigation:hover {
  background: #EEFFF4 !important;
  border: none !important;
  color: #01A24A !important;
  cursor: default !important;
}

.btn-submit {
  padding: 25px 25px;
  background: #00BD52;
  border: none;
  color: #FFFFFF;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
}

.btn-submit:hover {
  background: #00BD52 !important;
  border: none !important;
  color: #FFFFFF !important;
}

.btn-back {
  padding: 25px 25px;
  background: #FFFFFF;
  border: 1px solid #D0D5DD;
  color: #344054;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
}

.btn-back:hover {
  background: #FFFFFF !important;
  border: 1px solid #D0D5DD !important;
  color: #344054 !important;
}

.btn-danger {
  padding: 25px 45px;
  background: #D92D20;
  border: none;
  color: #FFFF;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
}

.btn-danger:hover {
  background: #D92D20;
  border: none;
  color: #FFFF !important;
}

.btn-approve {
  padding: 25px 45px;
  background: #00BD52;
  border: none;
  color: #FFFFFF;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
}

.btn-approve:not(:disabled):hover {
  background: #00BD52 !important;
  border: none !important;
  color: #FFFFFF !important;
}

.btn-rev-donor {
  padding: 22px 25px;
  background: #EEFFF4;
  border: 1px solid #E34F13;
  color: #E34F13;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
}