.parent-wrapper {

	.wrapper {
		padding: 20px;
		background-color: aliceblue;
		width: 1064px;
		height: 421px;
		margin-bottom: 30px;
	}

	.donor-sektor {
		display: flex;
		flex-direction: row;
		// justify-content: space-between;
	}
}

.ant-select-selection--multiple
{
   white-space: nowrap;
   height: 30px;
   overflow: auto
}