.ql-picker.ql-size .ql-picker-label::before,
.ql-picker.ql-size .ql-picker-item::before {
	content: attr(data-value) !important;
}

.btn-quill {
	border: solid 1px #000;
	padding: 5px;
}

.ql-container.ql-snow {
	border: 1px solid #CCC;
	min-height: 200px;
	white-space: normal !important
}

.ql-editor {
	white-space: normal !important;
}

.ql-clipboard {
	left: -100000px;
	height: 1px;
	overflow-y: hidden;
	position: absolute;
	top: 50%;
	white-space: pre-wrap;
	word-wrap: break-word;
}